.contact-form {
	background: #fff;
	padding: 2rem;

}

.contact-content {
	position: relative;
	width: 750px;
	max-width: 100%;
}

.contact-form input, .contact-form textarea {
	width: 100%;
	border: 1px solid #ddd;
	font-family:'Montserrat',sans-serif !important;
}

.contact-form label {
	color: #000;
	font-family: 'Montserrat', sans-serif;
}

.wpcf7-form p, .wpcf7-form-control-wrap {
	margin-bottom: 1rem;
}

.gform_title {
	font-family: 'Montserrat', sans-serif;
	text-align: center;
}

.ginput_product_price, .ginput_product_price_label, .ginput_total {
	font-family: 'Montserrat', sans-serif !important;
}
