.button, input[type="submit"] {
	display: inline-block;
	background: $cta-background;
	color: $cta-color;
	border-color: $cta-color;
	border-radius: 20px;
	padding: .75rem 2rem;
	transition: all .3s ease;
	text-transform: uppercase;

	&:hover {
		color: $cta-background;
		border-color: $cta-background;
		background: $cta-color;
	}
}

.extra-button {
	position: absolute;
	margin-left:250px;

	@include phone {
		position:relative;
		margin-left:0;
	}
}
