.wysiwyg-content {
	padding-top: 4rem;
	background: $light-gray;
}

.wysiwyg-content h3 {
	padding-bottom: .5rem;
}

.wysiwyg-content p {
	margin-bottom: 1rem;
}

.wysiwyg-content .section-heading {
	text-align: left;
}