.grid-view {
	background: $light-gray;
	text-align: center;
	padding: 5rem 0
}

.grid-view-inner {
	display: flex;
	flex-wrap: wrap;
}

.grid-view-item {
	border: 1px solid #ddd;
	background: #fff;
	padding: 2rem;
	width: 32%;
	margin-right: 2%;
	text-align: center;
	margin-bottom: 2%;

	&:nth-child(3n+3) {
		margin-right: 0;
	}

	@include tablet {
		width: 49%;

		&:nth-child(3n+3) {
			margin-right: 2%;
		}

		&:nth-child(2n+2) {
			margin-right: 0;
		}
	}

	@include phone {
		width: 100%;
		margin-right: 0;
	}
}

.grid-view-item h4 {
	margin: 1rem 0;
}

.grid-view-item p {
	font-size: 16px;
}

.grid-view-image {
	width: 60px;
	height: 60px;
	background: $cta-background;
	border-radius: 50%;
	margin: 0 auto;

	img {
		width: 50%;
		position: relative;
		top: 50%;
		transform: translateY(-50%);
	}
}

.videoContainer {
    padding:56.25% 0 0 0;
    position:relative;

    iframe {
        position:absolute;
        top:0;left:0;
        width:100%;
        height:100%;
    }
}
