.centerContainer {
  display:block; 
  margin-left: auto !important; 
  margin-right: auto !important;
  width: $wrapper-max-width;
  max-width: 100%;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

.centerContainerNoPadding {
  display:block; 
  margin-left: auto !important; 
  margin-right: auto !important;
  width: $wrapper-max-width;
  max-width: 100%;
}

.centerContainerPlusSome {
  display:block; 
  margin-left: auto !important; 
  margin-right: auto !important;
  width: 96%;
  max-width: 1500px;
}