.header {
	padding-top: 2rem;
}

.header a.phone-number {
	color: $cta-color;
	text-decoration: none;
	border-top: 2px solid $cta-color;
	margin-top: .5rem;
	padding-top: 5px;
	display: inline-block;

	&:hover {
		text-decoration: underline;
	}
}