.hero-content-container {
	position: relative;
	background-size: cover; 
	min-height: 100vh;

	@include phone {
		height: auto;
		min-height: 100vh;
	}
}

.hero-content-wrapper {
	padding-top: 3rem;
	padding-bottom: 3rem;
}

.hero-content {
	width: 750px;
	max-width: 100%;
}

.hero-content h1 {
	color: #fff;
	font-size: 60px;
	margin-bottom: 1rem;

	@include tablet {
		font-size: 40px;
	}

	@include phone {
		font-size: 30px;
	}
}

.hero-content p {
	line-height: 1.5;
	color: #fff;
	margin: 0 auto 1.5rem auto;
}