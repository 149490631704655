.footer {
	text-align: center;
	padding: 3rem 0;
	background: $cta-background;
	color: $cta-color;
}

.footer a.phone-number {
	color: $cta-color;
	border-top: 2px solid $cta-color;
	text-decoration: none;
	margin: 1rem;
	padding-top: 5px;
	display: inline-block;

	&:hover {
		text-decoration: underline;
	}
}