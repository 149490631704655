.section-heading {
	padding: 0 2rem 4rem;
	text-align: center;
}

.section-heading h3 {
	font-weight: 400;
	margin-bottom: 1rem;
	font-size: 25px;
}
