.split-view {
	display: flex;
	flex-wrap: wrap;
}
.split-view-flipped {

	@include tablet-down {
		flex-direction: column-reverse;
	}

}

.split-view-item {
	width: 50%;
	background-size: cover;
	background-position: center;

	@include tablet-down {
		width: 100%
	}
}

.split-view-item img {
	display: none;

	@include tablet-down {
		display: block;
		width: 100%;
	}
}

.split-view-item-content {
	padding: 5rem 3rem;
}

.split-view-item-content h2 {
	font-weight: 400;
	font-size: 40px;
	margin-bottom: 2rem;
}

.split-view-item-content p {
	margin-bottom: 1rem;
}

.split-view-item-content {
	ul, ol {
		color: black;
		margin: 2rem 0;
	}
}

.split-view-item-headline {
	padding: 2rem;

	h2 {
		font-size: 35px;
		font-weight: normal;
	}
}
