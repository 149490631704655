/* ==========================================================================
   VARIABLES
   ========================================================================== */
/**
 * Wrapper
 */


$wrapper-max-width: 1250px !default;

$sans-serif: var(--site-font);
$cta-background: var(--cta-background);
$cta-color: var(--cta-color);

$gray: #878787;
$light-gray: #FAFAFA;