.full-image {
	background-size: cover;
	position: relative;
	padding-top: 4rem;
	padding-bottom: 4rem;
}

.full-image .section-heading {
	z-index: 2;
	position: relative;
	color: #fff;
	padding: 4rem 2rem;
}

.full-image .button {
	margin-top: 1rem;
}

.full-image-content {
	width: 700px;
	max-width: 100%;
}