.full-text {
	padding-top: 4rem;
	background: $light-gray;
	border-top: 1px solid $cta-background;
}

.full-text .section-heading {
	width: 700px;
	max-width: 100%;
}

.full-text .button {
	margin-top: 1rem;
}